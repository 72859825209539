.bMainMenu {
    background-color: $cDarkGray;
    color: $cWhite;
    display: flex;
    flex-direction: column;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;

    &__inner {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        overflow-y: auto;
        padding-bottom: 26px;
        padding-top: 18px;
        position: relative;
    }

    &__logo {
        margin-bottom: 22px;
    }

    &__phoneNumber {
        color: inherit;
        display: inline-block;
        font: $tfHeadline;
        font-weight: 900;
        margin-top: 6px;

        &:hover {
            color: inherit;
        }
    }

    &__closeBtn {
        color: $cGreen;
        position: absolute;
        right: 8px;
        top: 8px;
    }

    &[hidden] {
        display: none;
    }
}
