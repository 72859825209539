/* stylelint-disable selector-max-type */
html,
body {
    margin: 0;
    padding: 0;
    width: 100%;
}

html {
    box-sizing: border-box;
    height: 100%;
}

/* stylelint-disable selector-max-universal */
*,
*::before,
*::after {
    box-sizing: inherit;
    outline: none;
}

body {
    background-color: $cWhite;
    color: $cBlack;
    font: $tfBody-1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 500;
    height: 100%;
    overflow-y: auto;
    -webkit-text-size-adjust: 100%;
}

input[type='number'] {
    -moz-appearance: textfield;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
/* stylelint-enable */

/* stylelint-disable-next-line selector-max-type */
img {
    display: block;
    max-height: 100%;
    max-width: 100%;
}

/* stylelint-disable selector-max-compound-selectors, selector-max-type */
a img {
    border: none;
}
/* stylelint-enable */

/* stylelint-disable selector-max-type */
a {
    color: $cGreen;
    font: $tfBody-2;
    text-decoration: none;
    transition: color 0.4s ease-in-out;

    &:hover,
    &:focus {
        color: $cDarkGreen;
        text-decoration: none;
    }

    &.isDisable,
    &._disabled {
        opacity: 0.5;
        pointer-events: none;
    }
}
/* stylelint-enable */

/* stylelint-disable-next-line selector-max-type */
h1,
h2,
h3,
.h1,
.h2,
.h3 {
    margin: 0;
    padding: 0;
}

/* stylelint-disable-next-line selector-max-type */
h1,
.h1 {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 24px;
}

/* stylelint-disable-next-line selector-max-type */
h2,
.h2 {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 16px;
}

/* stylelint-disable-next-line selector-max-type */
h3,
.h3 {
    font-size: 14px;
    line-height: 20px;
}

.headline {
    font: 900 $tfHeadline;
    margin-top: 24px;
    text-transform: uppercase;
}

.subtitle {
    font: $tfBody-2;
    font-weight: 700;
}

.caption {
    font: $tfCaption;
    font-weight: 700;
}

/* stylelint-disable selector-max-type */
label {
    display: block;
}
/* stylelint-enable */

.label {
    display: block;
    padding: 0 16px 4px;
}

.inputError {
    color: $cRed;
    display: block;
    font: $tfCaption;
    font-weight: 700;
    padding: 8px 16px 0;
}

/* stylelint-disable-next-line selector-max-type */
p {
    margin: 0;

    /* stylelint-disable-next-line selector-max-type */
    & + & {
        margin-top: 16px;
    }
}

/* stylelint-disable-next-line selector-max-type */
b,
strong {
    font-weight: 700;
}

.textWhite {
    color: $cWhite;
}

.textRed {
    color: $cRed;
}

.textGreen {
    color: $cGreen;
}

.textGray-40 {
    color: $cGray-40;
}

.visible-xs .visible-sm {
    display: none;
}

.textCenter {
    text-align: center;
}

.textBody-2 {
    font: $tfBody-2;
}

.textBold {
    font-weight: 700;
}

.title {
    font: $tfTitle;
    font-weight: 900;
}

.halfTransparent {
    opacity: 0.5;
}

/* stylelint-disable-next-line selector-max-type */
form {
    margin: 0;
}

.count {
    margin-right: 11px;
}

/* stylelint-disable declaration-no-important */
.visuallyHidden {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px !important;
    overflow: hidden;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
}
/* stylelint-enable */

.bgGreen {
    background-color: $cGreen;
    color: $cWhite;
}

.bgRed {
    background-color: $cRed;
    color: $cWhite;
}

@media $xs {
    .hidden-xs {
        display: none;
    }

    .visible-xs {
        display: block;
    }
}

@media $sm {
    .hidden-sm {
        display: none;
    }

    .visible-sm {
        display: block;
    }
}
