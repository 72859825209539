.spacer {
    margin-bottom: 16px;

    &_xxs,
    &Xxs {
        margin-bottom: 4px;
    }

    &_xs,
    &Xs {
        margin-bottom: 8px;
    }

    &_sm,
    &Sm {
        margin-bottom: 12px;
    }

    &_lg,
    &Lg {
        margin-bottom: 20px;
    }

    &_xlg,
    &Xlg {
        margin-bottom: 40px;
    }
}

.spacerInline {
    margin-right: 16px;

    &_xxs,
    &Xxs {
        margin-right: 4px;
    }

    &_xs,
    &Xs {
        margin-right: 8px;
    }

    &_sm,
    &Sm {
        margin-right: 12px;
    }

    &_lg,
    &Lg {
        margin-right: 20px;
    }

    &_xlg,
    &Xlg {
        margin-bottom: 40px;
    }
}
