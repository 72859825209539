.formDescr {
    line-height: 16px;
    padding-top: 8px;
}

.formLabel {
    align-items: center;
    color: color($cBlack a(0.6));
    display: flex;
    font-size: 11px;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 4px;
    min-height: 16px;
    text-transform: uppercase;

    &_inline {
        display: inline-block;
        margin-bottom: 0;
        margin-right: 20px;
        vertical-align: baseline;
    }

    &_noMargin {
        margin: 0;
    }
}

.formInputWithIcon {
    display: block;
    position: relative;

    &__icon {
        color: $cGrey;
        cursor: pointer;
        font-size: 16px;
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
    }
}
