/* Colors */
/* Fonts */
/* Typeface */
/* Border-radius */
/* Height */
/* Width */
/* zIndex */
/* Transitions */
/* media queries */
@font-face {
    font-family: Acrom;
    font-weight: 400;
    src: url(/static/media/acrom-regular.e378840c.woff2) format('woff2'),
        url(/static/media/acrom-regular.aa278b06.woff) format('woff');
}
@font-face {
    font-family: Acrom;
    font-weight: 500;
    src: url(/static/media/acrom-medium.2777ca27.woff2) format('woff2'),
        url(/static/media/acrom-medium.52d0676c.woff) format('woff');
}
@font-face {
    font-family: Acrom;
    font-weight: 700;
    src: url(/static/media/acrom-bold.758015ed.woff2) format('woff2'),
        url(/static/media/acrom-bold.e0f1ea9c.woff) format('woff');
}
@font-face {
    font-family: Acrom;
    font-weight: 900;
    src: url(/static/media/acrom-extrabold.5d1af832.woff2) format('woff2'),
        url(/static/media/acrom-extrabold.0a0a1abc.woff) format('woff');
}
/* UI */
/* stylelint-disable unit-blacklist */
.icon {
    display: inline-block;
    fill: currentColor;
    flex: none;
    height: 1em;
    line-height: 1em;
    position: relative;
    vertical-align: middle;
    width: 1em;
}
.icon_lg {
        font-size: 24px;
    }
.icon_sizeFull {
        height: 100%;
        width: 100%;
    }
/* stylelint-enable */
.aligner {
    align-items: center;
    display: flex;
}
.aligner__left {
        flex: 0 0 auto;
    }
.aligner__right {
        flex: 0 1 auto;
        margin-left: auto;
        min-width: 0;
        padding-left: 10px;
    }
.aligner_top {
        align-items: flex-start;
    }
.aligner_baseline {
        align-items: baseline;
    }
.badge {
    align-items: center;
    background: #999999;
    border: 1px solid transparent;
    border-radius: 2px;
    box-sizing: border-box;
    color: #ffffff;
    display: inline-flex;
    font-size: 10px;
    height: 24px;
    max-width: 100%;
    padding: 0 16px;
    text-transform: uppercase;
}
.badge_red {
        background: #ff7f80;
    }
.badge_green {
        background: #39b369;
    }
.badge__text {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
.btn {
    align-items: center;
    background: #39b369;
    border: none;
    border-radius: 24px;
    color: #ffffff;
    cursor: pointer;
    display: inline-flex;
    font: 500 16px/24px 'Acrom', 'Arial', sans-serif;
    font-weight: 700;
    height: 48px;
    justify-content: center;
    overflow: hidden;
    padding: 0 16px;
    text-align: center;
    text-overflow: ellipsis;
    text-transform: uppercase;
    transition-duration: 0.4s;
    transition-property: box-shadow, color, background, border-color;
    transition-timing-function: ease-in-out;
    vertical-align: middle;
    white-space: nowrap;
}
.btn:hover, .btn:active, .btn:focus {
        color: #ffffff;
        text-decoration: none;
    }
.btn:hover, .btn:focus {
        background-color: #31995a;
    }
.btn:active {
        background-color: #29804b;
    }
.btn__icon, .btn__leftIcon {
        height: 24px;
        width: 24px;
    }
.btn__leftIcon {
        margin-right: 8px;
    }
.btn_onlyIcon {
        padding: 12px;
        width: 48px;
    }
/* stylelint-disable no-descending-specificity */
.btn_borderGreen, .btn_borderRed, .btn_borderBlack {
        background-color: transparent;
        border-style: solid;
        border-width: 2px;
        color: #000000;
    }
.btn_borderGreen:hover, .btn_borderGreen:focus, .btn_borderGreen:active, .btn_borderRed:hover, .btn_borderRed:focus, .btn_borderRed:active, .btn_borderBlack:hover, .btn_borderBlack:focus, .btn_borderBlack:active {
            background-color: inherit;
            color: inherit;
        }
.btn_borderGreen {
        border-color: #39b369;
    }
.btn_borderRed {
        border-color: #ff7f80;
    }
.btn_borderBlack {
        border-color: #000;
    }
/* stylelint-enable no-descending-specificity */
.btn_hasShadow {
        box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
    }
.btn_bgTransparent {
        background-color: transparent;
        color: inherit;
    }
.btn_bgTransparent:hover, .btn_bgTransparent:active, .btn_bgTransparent:focus {
            background-color: inherit;
            color: inherit;
        }
.btn_bgOnGreen {
        background-color: #ffffff;
        color: #39b369;
    }
.btn_bgOnGreen:hover {
            background-color: #ffffff;
            color: #39b369;
        }
.btn_bgOnRed {
        background-color: #ffffff;
        color: #ff7f80;
    }
.btn_bgOnRed:hover {
            background-color: #ffffff;
            color: #ff7f80;
        }
.btn_bgBlack {
        background-color: #000;
    }
.btn_bgBlack:hover, .btn_bgBlack:active, .btn_bgBlack:focus {
            background-color: #333;
        }
.btn_widthFull {
        display: flex;
        padding: 0 10px;
        width: 100%;
    }
.btn._disabled, .btn[disabled] {
        cursor: default;
        opacity: 0.5;
        pointer-events: none;
    }
.btnGroup {
    border: 1px solid #39b369;
    border-radius: 2px;
    box-sizing: border-box;
    display: inline-flex;
    height: 40px;
    overflow: hidden;
}
.btnGroup__item {
        align-items: center;
        background: transparent;
        cursor: pointer;
        display: flex;
        justify-content: center;
        padding: 0 10px;
    }
.btnGroup__item:hover {
            background: #39b369;
            color: #ffffff;
        }
.btnGroup__item + .btnGroup__item {
            border-left: 1px solid #39b369;
        }
.btnGroup__item._active {
            background: #39b369;
            color: #ffffff;
            cursor: default;
        }
.btnGroup_block {
        display: flex;
    }
.btnGroup_block .btnGroup__item {
            flex: 1 0 0;
        }
/* stylelint-disable */
.checkbox, .radio {
    display: inline-flex;
    max-width: 210px;
}
.checkbox__btn {
        border-radius: 3px;
        box-shadow: inset 0 0 0 2px currentColor;
        display: block;
        height: 16px;
        margin-right: 12px;
        min-width: 16px;
        position: relative;
        width: 16px;
    }
.checkbox__btn::before {
            border-radius: 50%;
            content: '';
            height: calc(100% + 24px);
            left: 50%;
            opacity: 0;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%) scale(0.5);
            transition: all 0.3s ease-out;
            width: calc(100% + 24px);
            z-index: -1;
        }
.checkbox__btn::after {
            background-image: url('data:image/svg+xml,%3Csvg viewBox="0 0 10 7" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M1.70711 2.29289C1.31658 1.90237 0.683417 1.90237 0.292893 2.29289C-0.0976311 2.68342 -0.0976311 3.31658 0.292893 3.70711L1.70711 2.29289ZM4 6L3.29289 6.70711C3.68342 7.09763 4.31658 7.09763 4.70711 6.70711L4 6ZM9.70711 1.70711C10.0976 1.31658 10.0976 0.683417 9.70711 0.292893C9.31658 -0.0976311 8.68342 -0.0976311 8.29289 0.292893L9.70711 1.70711ZM0.292893 3.70711L3.29289 6.70711L4.70711 5.29289L1.70711 2.29289L0.292893 3.70711ZM4.70711 6.70711L9.70711 1.70711L8.29289 0.292893L3.29289 5.29289L4.70711 6.70711Z" fill="white"/%3E%3C/svg%3E%0A');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            content: '';
            height: 7px;
            left: 50%;
            opacity: 0;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 10px;
        }
.checkbox__label, .radio__label {
        color: #333333;
        text-align: left;
    }
.checkbox__label a, .radio__label a {
            font-size: inherit;
            line-height: inherit;
        }
.checkbox__input:checked + .checkbox__btn, .radio__input:checked + .checkbox__btn {
        background-color: #39b369;
        box-shadow: none;
    }
.checkbox__input:checked + .checkbox__btn::after, .radio__input:checked + .checkbox__btn::after {
            opacity: 1;
        }
.checkbox__input:disabled ~ .checkbox__btn, .checkbox__input:disabled ~ .checkbox__label, .radio__input:disabled ~ .checkbox__btn, .radio__input:disabled ~ .checkbox__label {
        opacity: 0.5;
    }
@media (hover: hover) {
            .checkbox:hover .checkbox__input:not(:disabled) + .checkbox__btn::before, .checkbox__input:focus + .checkbox__btn::before, .radio:hover .checkbox__input:not(:disabled) + .checkbox__btn::before, .radio__input:focus + .checkbox__btn::before {
                opacity: 0.5;
                transform: translate(-50%, -50%) scale(1);
            }
            .checkbox:hover .checkbox__input:not(:disabled) + .checkbox__btn::before, .radio:hover .checkbox__input:not(:disabled) + .checkbox__btn::before {
                background-color: #bfbfbf;
            }
            .checkbox__input:focus + .checkbox__btn::before, .radio__input:focus + .checkbox__btn::before {
                background-color: #d9d9d9;
            }
    }
.flex {
    display: flex;
    flex-direction: column;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}
.flex_horizontal {
        flex-direction: row;
    }
.flex__bar {
        flex: none;
        position: relative;
    }
.flex__scroll {
        flex: auto;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        position: relative;
    }
.formDescr {
    line-height: 16px;
    padding-top: 8px;
}
.formLabel {
    align-items: center;
    color: rgba(0, 0, 0, 0.6);
    display: flex;
    font-size: 11px;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 4px;
    min-height: 16px;
    text-transform: uppercase;
}
.formLabel_inline {
        display: inline-block;
        margin-bottom: 0;
        margin-right: 20px;
        vertical-align: baseline;
    }
.formLabel_noMargin {
        margin: 0;
    }
.formInputWithIcon {
    display: block;
    position: relative;
}
.formInputWithIcon__icon {
        color: #999999;
        cursor: pointer;
        font-size: 16px;
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
    }
.formSlider {
    padding-bottom: 24px;
    padding-top: 12px;
    position: relative;
}
.formSlider__line {
        background: rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        height: 6px;
        position: relative;
        width: 100%;
    }
.formSlider__pin {
        height: 100%;
        position: absolute;
        width: 0;
    }
.formSlider__pin::after {
            background: #a8b1bf;
            border-radius: 50%;
            box-shadow: 0 3px 5px 0 rgba(12, 12, 66, 0.2);
            content: '';
            cursor: pointer;
            height: 16px;
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 16px;
        }
.formSlider__label {
        bottom: 0;
        display: flex;
        justify-content: center;
        position: absolute;
        text-align: center;
        width: 0;
    }
.formSlider__label_first {
            justify-content: flex-start;
        }
.formSlider__label_last {
            justify-content: flex-end;
        }
/* stylelint-disable-next-line selector-max-type */
hr, .hr {
    border: none;
    border-top: 1px solid #d9d9d9;
    margin: 16px 0;
}
.input, .textarea, .select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #ffffff;
    border: 1px solid #a6a6a6;
    border-radius: 24px;
    box-shadow: none;
    color: #000000;
    display: inline-block;
    font: 500 14px/20px 'Acrom', 'Arial', sans-serif;
    height: 48px;
    outline: none;
    padding: 0 16px;
    text-align: inherit;
    text-overflow: ellipsis;
    width: 100%;
}
.input:focus, .textarea:focus, .select:focus {
        border-color: #39b369;
    }
/* stylelint-disable-next-line order/order */
.input:focus::placeholder, .textarea:focus::placeholder, .select:focus::placeholder {
        color: transparent;
    }
/* stylelint-disable-next-line no-descending-specificity */
.input::placeholder, .textarea::placeholder, .select::placeholder {
        color: #999999;
    }
.input._disabled, .input[disabled], .textarea._disabled, .select._disabled {
        opacity: 0.5;
    }
.input.hasError, .textarea.hasError, .select.hasError {
        border-color: #ff7f80;
    }
.input.lg, .textarea.lg, .select.lg {
        font-size: 24px;
        line-height: 28px;
    }
.list + .list {
        margin-top: 2px;
    }
.list__item {
        line-height: 22px;
        padding-left: 20px;
        position: relative;
    }
.list__item::before {
            content: '\2014';
            left: 0;
            position: absolute;
            top: 0;
        }
.popover {
    background: #ffffff;
    border-radius: 8px;
    color: #000000;
    filter: drop-shadow(0 8px 15px rgba(#000000, 0.5));
    max-width: 300px;
    position: absolute;
}
.popover::before {
        background: #ffffff;
        content: '';
        height: 18px;
        left: -7px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
        width: 18px;
    }
.popover__close {
        align-items: center;
        cursor: pointer;
        display: flex;
        font-size: 8px;
        height: 24px;
        justify-content: center;
        opacity: 0.5;
        position: absolute;
        right: 0;
        top: 0;
        width: 24px;
        z-index: 1;
    }
.popover__content {
        padding: 20px 25px;
    }
.radio {
    /* stylelint-disable-next-line at-rule-no-unknown */
}
.radio__label {
        /* stylelint-disable-next-line at-rule-no-unknown */
    }
.radio__label::before {
            border-radius: 50%;
        }
.radio__labelText {
        /* stylelint-disable-next-line at-rule-no-unknown */
    }
.radio__input {
        /* stylelint-disable-next-line at-rule-no-unknown */
    }
.radio__input:checked + .radio__label::after {
                background: #39b369;
                border-radius: 50%;
                content: '';
                height: 12px;
                left: 4px;
                position: absolute;
                top: 4px;
                width: 12px;
            }
.radio__input[disabled] + .radio__label, .radio__input._disabled + .radio__label {
            cursor: default;
            opacity: 0.5;
        }
.section {
    margin: 0 auto;
    max-width: 1140px;
    padding-left: 30px;
    padding-right: 30px;
}
.section_short {
        max-width: 1000px;
    }
.spacer {
    margin-bottom: 16px;
}
.spacer_xxs, .spacerXxs {
        margin-bottom: 4px;
    }
.spacer_xs, .spacerXs {
        margin-bottom: 8px;
    }
.spacer_sm, .spacerSm {
        margin-bottom: 12px;
    }
.spacer_lg, .spacerLg {
        margin-bottom: 20px;
    }
.spacer_xlg, .spacerXlg {
        margin-bottom: 40px;
    }
.spacerInline {
    margin-right: 16px;
}
.spacerInline_xxs, .spacerInlineXxs {
        margin-right: 4px;
    }
.spacerInline_xs, .spacerInlineXs {
        margin-right: 8px;
    }
.spacerInline_sm, .spacerInlineSm {
        margin-right: 12px;
    }
.spacerInline_lg, .spacerInlineLg {
        margin-right: 20px;
    }
.spacerInline_xlg, .spacerInlineXlg {
        margin-bottom: 40px;
    }
.table {
    border-collapse: collapse;
    width: 100%;
}
.table_hover .table__tr:hover {
                background: rgb(230, 230, 230);
            }
.table__overflowText {
        left: 5px;
        max-width: calc(100% - 10px);
        overflow: hidden;
        position: absolute;
        text-overflow: ellipsis;
        top: 3px;
        white-space: nowrap;
    }
.table .table__th, .table .table__td {
        background-clip: padding-box;
        line-height: 20px;
        padding: 3px 5px;
        position: relative;
        vertical-align: middle;
    }
.table .table__th {
        background: #ffffff;
        border-bottom: 1px solid #999999;
        color: #999999;
        font-weight: normal;
        text-align: left;
    }
.table .table__td {
        border-bottom: 1px solid #d5e0ed;
    }
.tabs {
    border-bottom: 1px solid #999999;
    display: flex;
}
.tabs__item {
        align-items: center;
        background: #eaeff1;
        border: 1px solid rgb(191, 191, 191);
        border-bottom-color: #999999;
        border-radius: 4px 4px 0 0;
        box-sizing: border-box;
        cursor: pointer;
        display: flex;
        height: 26px;
        margin-bottom: -1px;
        margin-right: 2px;
        padding: 0 20px 0 15px;
    }
.tabs__item:hover {
            background: rgb(140, 140, 140);
            border: 1px solid rgb(140, 140, 140);
            border-bottom-color: #999999;
        }
.tabs__right {
        margin-left: auto;
    }
.tabs._active {
        background: #ffffff;
        border: 1px solid #999999;
        border-bottom-color: #ffffff;
        cursor: default;
    }
.textarea {
    /* stylelint-disable-next-line at-rule-no-unknown */

    height: auto;
    max-width: 100%;
    min-height: 80px;
    overflow: auto;
    padding: 8px 12px;
    width: 100%;
}
.tooltip {
    background: #ffffff;
    border-radius: 3px;
    color: #000000;
    filter: drop-shadow(0 2px 5px rgba(0, 0, 0, 0.2));
    font-size: 12px;
    opacity: 0;
    padding: 6px 12px;
    position: absolute;
    transition: all 0.3s ease;
    transition-property: opacity;
    visibility: hidden;
}
.tooltip::after {
        background: #ffffff;
        content: '';
        height: 12px;
        left: 50%;
        position: absolute;
        top: 100%;
        transform: translate(-50%, -50%) rotate(45deg);
        width: 12px;
    }
.tooltip._show {
        opacity: 1;
        visibility: visible;
    }
.vsection {
    padding-bottom: 30px;
    padding-top: 30px;
}
.panel {
    background-color: #ffffff;
    border-radius: 4px;
    margin-left: -12px;
    margin-right: -12px;
    padding: 20px;
}
.panel_hasShadow {
        box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
    }
.select {
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 6"%3E%3Cpath fill="%2339B369" class="st0" d="M0.3,0.3c0.4-0.4,1-0.4,1.4,0L5,3.6l3.3-3.3c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1,0,1.4l-4,4 c-0.4,0.4-1,0.4-1.4,0l-4-4C-0.1,1.3-0.1,0.7,0.3,0.3z"/%3E%3C/svg%3E%0A');
    background-position: calc(100% - 20px) center;
    background-repeat: no-repeat;
    background-size: 10px 6px;
    padding-right: 48px;

    /* stylelint-disable-next-line at-rule-no-unknown */
}
/* stylelint-disable-next-line order/order */
.select:invalid {
        color: #999999;
    }
/* stylelint-disable-next-line no-descending-specificity */
.select::-ms-expand {
        display: none; /* remove default arrow on ie10 and ie11 */
    }
/* stylelint-disable */
.spinner {
    display: flex;
    justify-content: center;
}
.spinner > div {
        animation: bouncing-loader 0.6s infinite alternate;
        background: #39b369;
        border-radius: 50%;
        height: 1rem;
        margin: 3rem 0.2rem;
        width: 1rem;
    }
.spinner > div:nth-child(2) {
            animation-delay: 0.2s;
        }
.spinner > div:nth-child(3) {
            animation-delay: 0.4s;
        }
.spinner._small > div {
        margin-bottom: 0;
    }
.spinner._white > div {
        background: #ffffff
    }
@keyframes bouncing-loader {
    from {
        transform: translateY(0);
    }

    to {
        transform: translateY(-1rem);
    }
}
/* stylelint-enable */
/* stylelint-disable selector-max-type */
html, body {
    margin: 0;
    padding: 0;
    width: 100%;
}
html {
    box-sizing: border-box;
    height: 100%;
}
/* stylelint-disable selector-max-universal */
*, *::before, *::after {
    box-sizing: inherit;
    outline: none;
}
body {
    background-color: #ffffff;
    color: #000000;
    font: 500 14px/20px 'Acrom', 'Arial', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 500;
    height: 100%;
    overflow-y: auto;
    -webkit-text-size-adjust: 100%;
}
input[type='number'] {
    -moz-appearance: textfield;
}
input[type='number']::-webkit-inner-spin-button, input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
/* stylelint-enable */
/* stylelint-disable-next-line selector-max-type */
img {
    display: block;
    max-height: 100%;
    max-width: 100%;
}
/* stylelint-disable selector-max-compound-selectors, selector-max-type */
a img {
    border: none;
}
/* stylelint-enable */
/* stylelint-disable selector-max-type */
a {
    color: #39b369;
    font: 500 16px/24px 'Acrom', 'Arial', sans-serif;
    text-decoration: none;
    transition: color 0.4s ease-in-out;
}
a:hover, a:focus {
        color: #31995a;
        text-decoration: none;
    }
a.isDisable, a._disabled {
        opacity: 0.5;
        pointer-events: none;
    }
/* stylelint-enable */
/* stylelint-disable-next-line selector-max-type */
h1, h2, h3, .h1, .h2, .h3 {
    margin: 0;
    padding: 0;
}
/* stylelint-disable-next-line selector-max-type */
h1, .h1 {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 24px;
}
/* stylelint-disable-next-line selector-max-type */
h2, .h2 {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 16px;
}
/* stylelint-disable-next-line selector-max-type */
h3, .h3 {
    font-size: 14px;
    line-height: 20px;
}
.headline {
    font: 900 24px/28px 'Acrom', 'Arial', sans-serif;
    margin-top: 24px;
    text-transform: uppercase;
}
.subtitle {
    font: 500 16px/24px 'Acrom', 'Arial', sans-serif;
    font-weight: 700;
}
.caption {
    font: 12px/16px 'Acrom', 'Arial', sans-serif;
    font-weight: 700;
}
/* stylelint-disable selector-max-type */
label {
    display: block;
}
/* stylelint-enable */
.label {
    display: block;
    padding: 0 16px 4px;
}
.inputError {
    color: #ff7f80;
    display: block;
    font: 12px/16px 'Acrom', 'Arial', sans-serif;
    font-weight: 700;
    padding: 8px 16px 0;
}
/* stylelint-disable-next-line selector-max-type */
p {
    margin: 0;
}
/* stylelint-disable-next-line selector-max-type */
p + p {
        margin-top: 16px;
    }
/* stylelint-disable-next-line selector-max-type */
b, strong {
    font-weight: 700;
}
.textWhite {
    color: #ffffff;
}
.textRed {
    color: #ff7f80;
}
.textGreen {
    color: #39b369;
}
.textGray-40 {
    color: #666666;
}
.visible-xs .visible-sm {
    display: none;
}
.textCenter {
    text-align: center;
}
.textBody-2 {
    font: 500 16px/24px 'Acrom', 'Arial', sans-serif;
}
.textBold {
    font-weight: 700;
}
.title {
    font: 32px/36px 'Acrom', 'Arial', sans-serif;
    font-weight: 900;
}
.halfTransparent {
    opacity: 0.5;
}
/* stylelint-disable-next-line selector-max-type */
form {
    margin: 0;
}
.count {
    margin-right: 11px;
}
/* stylelint-disable declaration-no-important */
.visuallyHidden {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px !important;
    overflow: hidden;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
}
/* stylelint-enable */
.bgGreen {
    background-color: #39b369;
    color: #ffffff;
}
.bgRed {
    background-color: #ff7f80;
    color: #ffffff;
}
@media (
    max-width: 767px
) {
    .hidden-xs {
        display: none;
    }

    .visible-xs {
        display: block;
    }
}
@media (
        min-width: 768px
    )
    and
    (
        max-width: 991px
    ) {
    .hidden-sm {
        display: none;
    }

    .visible-sm {
        display: block;
    }
}
/* blocks */
.bPage {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: hidden;
}
.bPage__main {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        overflow-x: hidden;
        overflow-y: auto;
        position: relative;
    }
.bLogo {
    color: inherit;
    display: inline-block;
    height: 28px;
    width: 157px;
}
.bLogo:hover {
        color: inherit;
    }
.bHeader {
    background-color: #ffffff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12);
    display: flex;
    min-height: 64px;
    position: relative;
    z-index: 100;
}
.bHeader__container {
        display: flex;
    }
.bHeader__nav {
        align-items: center;
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
    }
.bHeader__goBack {
        color: #39b369;
        padding: 0;
        text-transform: none;
    }
.bHeader__goBack:hover {
            color: #39b369;
        }
.bHeader__menuBtn {
        color: #39b369;
        margin-right: -12px;
    }
.bHeader__menuBtn:hover {
            color: #39b369;
        }
.bSmsCodeInput {
    display: inline-flex;
}
.bSmsCodeInput__item {
        border-radius: 4px;
        padding: 0 10px;
        width: 48px;
    }
.bSmsCodeInput__item + .bSmsCodeInput__item {
            margin-left: 8px;
        }
.bSmsCodeInput.hasError .bSmsCodeInput__item {
        border-color: #ff7f80;
    }
.bMap {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}
/* stylelint-disable-next-line order/order */
.bMap__placeholderImg {
        height: 100%;
        left: 0;
        object-fit: cover;
        position: absolute;
        top: 0;
        width: 100%;
    }
.bMap__content {
        bottom: 0;
        display: flex;
        left: 0;
        max-height: 100%;
        pointer-events: none;
        position: absolute;
        width: 100%;
    }
.bMap__contentContainer {
        flex-grow: 1;
        pointer-events: auto;
    }
.bMap__btn {
        margin: 20px 0;
    }
.bMap__panel {
        margin-top: 8px;
    }
.bMap__panel:last-child {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
.bMap__panel._minimized {
            /* stylelint-enable selector-max-type */
        }
/* stylelint-disable selector-max-type */
.bMap__panel._minimized .headline, .bMap__panel._minimized hr, .bMap__panel._minimized .btn {
                display: none;
            }
.bMap__panel._minimized tr:not([class*='cardSession__main']) {
                display: none;
            }
.bMap__panel._minimized tr.hr {
                border-top: none;
            }
.bMap__table {
        border-collapse: collapse;
        table-layout: fixed;
        width: 100%;
    }
.bMap__table td, .bMap__table th {
            padding: 0;
            vertical-align: top;
            white-space: nowrap;
        }
/* stylelint-disable-next-line selector-max-type */
.bMap__table td:first-child, .bMap__table th:first-child {
                text-align: left;
            }
/* stylelint-disable-next-line selector-max-type */
.bMap__table td:last-child, .bMap__table th:last-child {
                text-align: right;
            }
/* stylelint-disable selector-max-compound-selectors, selector-max-type */
.bMap [class*='ymaps-'] a {
        font-size: inherit;
    }
.bMap-cluster {
        border: 2px solid #39b369;
        border-radius: 50%;
        box-sizing: border-box;
        font-size: 16px;
        height: 40px;
        left: -20px;
        overflow: hidden;
        position: relative;
        top: -20px;
        width: 40px;
    }
.bMap-cluster::before {
            background: #ffffff;
            bottom: 0;
            content: '';
            left: 0;
            opacity: 0.7;
            position: absolute;
            right: 0;
            top: 0;
        }
.bMap-cluster-content {
            align-items: center;
            background: #39b369;
            border-radius: 50%;
            color: #ffffff;
            display: inline-flex;
            height: 28px;
            justify-content: center;
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 28px;
        }
.bMap__geolocationBtn {
        position: absolute;
        z-index: 1;
        top: 32px;
        right: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        padding: 0;
        background-color: #fff;
        border: none;
        border-radius: 50%;
        box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
        cursor: pointer;
    }
.bMap__geolocationBtn:active {
        opacity: 0.9;
    }
.bPopup__overlay {
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        left: 0;
        padding: 8px 0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 150;
    }
.bPopup__overlay._spinner {
            z-index: 155;
        }
.bPaysystemIcons {
    display: flex;
    justify-content: space-between;
}
.bPaysystemIcons__item {
        height: 60px;
        margin: 9px;
        width: 94px;
    }
.bOrderedList {
    counter-reset: orderList;
    list-style: none;
    margin: 0;
    padding: 0;
}
.bOrderedList__number {
        align-items: center;
        border: 2px solid #000000;
        border-radius: 50%;
        display: flex;
        font: 32px/36px 'Acrom', 'Arial', sans-serif;
        font-weight: 700;
        height: 48px;
        justify-content: center;
        margin-right: 16px;
        min-width: 48px;
        width: 48px;
    }
.bOrderedList__number::before {
            content: counter(orderList);
            counter-increment: orderList;
        }
.bOrderedList__item {
        align-items: center;
        display: flex;
    }
.bOrderedList__item + .bOrderedList__item {
            margin-top: 16px;
        }
.bNavLinks {
    list-style: none;
    margin: 0;
    padding: 0;
}
.bNavLinks__link {
        color: inherit;
        cursor: pointer;
        display: block;
        font: 500 16px/24px 'Acrom', 'Arial', sans-serif;
        font-weight: 700;
        padding-bottom: 12px;
        padding-top: 12px;
    }
.bNavLinks__link:hover {
            color: inherit;
        }
.bNavLinks__item_divided {
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            padding-bottom: 8px;
        }
.bNavLinks__item_divided + .bNavLinks__item {
            padding-top: 8px;
        }
.bMainMenu {
    background-color: #394143;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
}
.bMainMenu__inner {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        overflow-y: auto;
        padding-bottom: 26px;
        padding-top: 18px;
        position: relative;
    }
.bMainMenu__logo {
        margin-bottom: 22px;
    }
.bMainMenu__phoneNumber {
        color: inherit;
        display: inline-block;
        font: 24px/28px 'Acrom', 'Arial', sans-serif;
        font-weight: 900;
        margin-top: 6px;
    }
.bMainMenu__phoneNumber:hover {
            color: inherit;
        }
.bMainMenu__closeBtn {
        color: #39b369;
        position: absolute;
        right: 8px;
        top: 8px;
    }
.bMainMenu[hidden] {
        display: none;
    }
.bAutomatCodeHint {
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;
}
.bAutomatCodeHint__caption {
        display: inline-block;
        margin-bottom: 28px;
        position: relative;
    }
.bAutomatCodeHint__callout {
        height: 93px;
        position: absolute;
        right: -12px;
        top: calc(100% + 5px);
        width: 170px;
        z-index: 1;
    }
.bAutomatCodeHint__imgWrapper {
        height: 205px;
        max-width: 320px;
        position: relative;
        width: 100%;
    }
.bAutomatCodeHint__img {
        height: 305px;
        left: 50%;
        max-height: none;
        max-width: none;
        position: absolute;
        top: 0;
        transform: translateX(-50%);
    }
.bPaymentCard {
    background-color: #d9d9d9;
    border-radius: 16px;
    padding: 20px 20px 28px;
}
.bPaymentCard__paymentSystemLogo {
        height: 60px;
        margin-bottom: 28px;
        width: 72px;
    }
.bPaymentCard__number {
        font: 24px/28px 'Acrom', 'Arial', sans-serif;
        margin-bottom: 12px;
    }
.bPaymentCard__hiddenSymbols {
        letter-spacing: 5px;
    }
.bPaymentCard__name {
        font: 500 16px/24px 'Acrom', 'Arial', sans-serif;
    }
.bIconizedList {
    counter-reset: orderList;
    list-style: none;
    margin: 0;
    padding: 0;
}
.bIconizedList__bullet {
        align-items: center;
        border-radius: 50%;
        display: flex;
        font: 32px/36px 'Acrom', 'Arial', sans-serif;
        font-weight: 700;
        height: 48px;
        justify-content: center;
        margin-right: 16px;
        min-width: 48px;
        width: 48px;
    }
.bIconizedList__item {
        display: flex;
    }
.bIconizedList__item + .bIconizedList__item {
            margin-top: 16px;
        }
.bIconizedList_bulletBgGreen .bIconizedList__bullet {
        background-color: #caf396;
    }
.bIconizedList_divided .bIconizedList__item:not(:last-child) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.3);
        padding-bottom: 20px;
    }
.bIconizedList_divided.textWhite .bIconizedList__item {
        border-color: rgba(255, 255, 255, 0.3);
    }
/* stylelint-disable selector-max-compound-selectors */
.bIconizedList_divided .bIconizedList__item + .bIconizedList__item {
        margin-top: 20px;
    }
.bBullet {
    align-items: center;
    border-radius: 50%;
    display: flex;
    font: 32px/36px 'Acrom', 'Arial', sans-serif;
    font-weight: 700;
    height: 48px;
    justify-content: center;
    margin-right: 16px;
    min-width: 48px;
    width: 48px;
}
.bBullet_bgGreen {
        background-color: #caf396;
    }
.bBullet_borderBlack {
        border: 2px solid #000000;
    }
.bStylizedList {
    list-style: none;
    margin: 0;
    padding: 0;
}
.bStylizedList__item {
        display: flex;
    }
.bStylizedList__item + .bStylizedList__item {
            margin-top: 16px;
        }
.bStylizedList__text {
        align-items: center;
        display: flex;
        font: 500 16px/24px 'Acrom', 'Arial', sans-serif;
    }
.bStylizedList_divided .bStylizedList__item:not(:last-child) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.3);
        padding-bottom: 20px;
    }
.bStylizedList_divided.textWhite .bStylizedList__item {
        border-color: rgba(255, 255, 255, 0.3);
    }
/* stylelint-disable selector-max-compound-selectors */
.bStylizedList_divided .bStylizedList__item + .bStylizedList__item {
        margin-top: 20px;
    }
.bStylizedList_styleOrder {
        counter-reset: orderList;
    }
.bStylizedList_styleOrder .bStylizedList__bullet::before {
            content: counter(orderList);
            counter-increment: orderList;
        }
.container, .container-fluid {
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  margin-right: auto;
  margin-left: auto;
}
@media screen and (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media screen and (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media screen and (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media screen and (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -12px;
  margin-left: -12px;
}
.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}
.col, .col-auto, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-sm, .col-sm-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-md, .col-md-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-lg, .col-lg-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-xl, .col-xl-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 12px;
  padding-left: 12px;
}
.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}
.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}
.col-1 {
  flex: 0 0 8.333333333333334%;
  max-width: 8.333333333333334%;
}
.col-2 {
  flex: 0 0 16.666666666666668%;
  max-width: 16.666666666666668%;
}
.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}
.col-4 {
  flex: 0 0 33.333333333333336%;
  max-width: 33.333333333333336%;
}
.col-5 {
  flex: 0 0 41.66666666666667%;
  max-width: 41.66666666666667%;
}
.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}
.col-7 {
  flex: 0 0 58.333333333333336%;
  max-width: 58.333333333333336%;
}
.col-8 {
  flex: 0 0 66.66666666666667%;
  max-width: 66.66666666666667%;
}
.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}
.col-10 {
  flex: 0 0 83.33333333333334%;
  max-width: 83.33333333333334%;
}
.col-11 {
  flex: 0 0 91.66666666666667%;
  max-width: 91.66666666666667%;
}
.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}
.order-first {
  order: -1;
}
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}
.order-4 {
  order: 4;
}
.order-5 {
  order: 5;
}
.order-6 {
  order: 6;
}
.order-7 {
  order: 7;
}
.order-8 {
  order: 8;
}
.order-9 {
  order: 9;
}
.order-10 {
  order: 10;
}
.order-11 {
  order: 11;
}
.order-12 {
  order: 12;
}
.offset-1 {
  margin-left: 8.333333333333334%;
}
.offset-2 {
  margin-left: 16.666666666666668%;
}
.offset-3 {
  margin-left: 25%;
}
.offset-4 {
  margin-left: 33.333333333333336%;
}
.offset-5 {
  margin-left: 41.66666666666667%;
}
.offset-6 {
  margin-left: 50%;
}
.offset-7 {
  margin-left: 58.333333333333336%;
}
.offset-8 {
  margin-left: 66.66666666666667%;
}
.offset-9 {
  margin-left: 75%;
}
.offset-10 {
  margin-left: 83.33333333333334%;
}
.offset-11 {
  margin-left: 91.66666666666667%;
}
@media screen and (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-sm-1 {
    flex: 0 0 8.333333333333334%;
    max-width: 8.333333333333334%;
  }

  .col-sm-2 {
    flex: 0 0 16.666666666666668%;
    max-width: 16.666666666666668%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.333333333333336%;
    max-width: 33.333333333333336%;
  }

  .col-sm-5 {
    flex: 0 0 41.66666666666667%;
    max-width: 41.66666666666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.333333333333336%;
    max-width: 58.333333333333336%;
  }

  .col-sm-8 {
    flex: 0 0 66.66666666666667%;
    max-width: 66.66666666666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.33333333333334%;
    max-width: 83.33333333333334%;
  }

  .col-sm-11 {
    flex: 0 0 91.66666666666667%;
    max-width: 91.66666666666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-1 {
    margin-left: 8.333333333333334%;
  }

  .offset-sm-2 {
    margin-left: 16.666666666666668%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.333333333333336%;
  }

  .offset-sm-5 {
    margin-left: 41.66666666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.333333333333336%;
  }

  .offset-sm-8 {
    margin-left: 66.66666666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333333334%;
  }

  .offset-sm-11 {
    margin-left: 91.66666666666667%;
  }
}
@media screen and (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-md-1 {
    flex: 0 0 8.333333333333334%;
    max-width: 8.333333333333334%;
  }

  .col-md-2 {
    flex: 0 0 16.666666666666668%;
    max-width: 16.666666666666668%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.333333333333336%;
    max-width: 33.333333333333336%;
  }

  .col-md-5 {
    flex: 0 0 41.66666666666667%;
    max-width: 41.66666666666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.333333333333336%;
    max-width: 58.333333333333336%;
  }

  .col-md-8 {
    flex: 0 0 66.66666666666667%;
    max-width: 66.66666666666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.33333333333334%;
    max-width: 83.33333333333334%;
  }

  .col-md-11 {
    flex: 0 0 91.66666666666667%;
    max-width: 91.66666666666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-1 {
    margin-left: 8.333333333333334%;
  }

  .offset-md-2 {
    margin-left: 16.666666666666668%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.333333333333336%;
  }

  .offset-md-5 {
    margin-left: 41.66666666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.333333333333336%;
  }

  .offset-md-8 {
    margin-left: 66.66666666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333333334%;
  }

  .offset-md-11 {
    margin-left: 91.66666666666667%;
  }
}
@media screen and (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-lg-1 {
    flex: 0 0 8.333333333333334%;
    max-width: 8.333333333333334%;
  }

  .col-lg-2 {
    flex: 0 0 16.666666666666668%;
    max-width: 16.666666666666668%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.333333333333336%;
    max-width: 33.333333333333336%;
  }

  .col-lg-5 {
    flex: 0 0 41.66666666666667%;
    max-width: 41.66666666666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.333333333333336%;
    max-width: 58.333333333333336%;
  }

  .col-lg-8 {
    flex: 0 0 66.66666666666667%;
    max-width: 66.66666666666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.33333333333334%;
    max-width: 83.33333333333334%;
  }

  .col-lg-11 {
    flex: 0 0 91.66666666666667%;
    max-width: 91.66666666666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-1 {
    margin-left: 8.333333333333334%;
  }

  .offset-lg-2 {
    margin-left: 16.666666666666668%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.333333333333336%;
  }

  .offset-lg-5 {
    margin-left: 41.66666666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.333333333333336%;
  }

  .offset-lg-8 {
    margin-left: 66.66666666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333333334%;
  }

  .offset-lg-11 {
    margin-left: 91.66666666666667%;
  }
}
@media screen and (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-xl-1 {
    flex: 0 0 8.333333333333334%;
    max-width: 8.333333333333334%;
  }

  .col-xl-2 {
    flex: 0 0 16.666666666666668%;
    max-width: 16.666666666666668%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.333333333333336%;
    max-width: 33.333333333333336%;
  }

  .col-xl-5 {
    flex: 0 0 41.66666666666667%;
    max-width: 41.66666666666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.333333333333336%;
    max-width: 58.333333333333336%;
  }

  .col-xl-8 {
    flex: 0 0 66.66666666666667%;
    max-width: 66.66666666666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.33333333333334%;
    max-width: 83.33333333333334%;
  }

  .col-xl-11 {
    flex: 0 0 91.66666666666667%;
    max-width: 91.66666666666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-1 {
    margin-left: 8.333333333333334%;
  }

  .offset-xl-2 {
    margin-left: 16.666666666666668%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.333333333333336%;
  }

  .offset-xl-5 {
    margin-left: 41.66666666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.333333333333336%;
  }

  .offset-xl-8 {
    margin-left: 66.66666666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333333334%;
  }

  .offset-xl-11 {
    margin-left: 91.66666666666667%;
  }
}
/* stylelint-disable-next-line selector-max-type */
.row div[class^='col'] {
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
}
.row {
    margin-left: -10px;
    margin-right: -10px;
}
.mb-auto {
    margin-bottom: auto;
}
.mb-0 {
    margin-bottom: 0;
}
.mb-4 {
    margin-bottom: 4px;
}
.mb-8 {
    margin-bottom: 8px;
}
.mb-16 {
    margin-bottom: 16px;
}
.mb-20 {
    margin-bottom: 20px;
}
.mb-24 {
    margin-bottom: 24px;
}
.mb-36 {
    margin-bottom: 36px;
}
.mb-40 {
    margin-bottom: 40px;
}
.mt-auto {
    margin-top: auto;
}
.mt-0 {
    margin-top: 0;
}
.mt-4 {
    margin-top: 4px;
}
.mt-8 {
    margin-top: 8px;
}
.mt-16 {
    margin-top: 16px;
}
.mt-20 {
    margin-top: 20px;
}
.mt-24 {
    margin-top: 24px;
}
.mt-36 {
    margin-top: 36px;
}
.mt-40 {
    margin-top: 40px;
}
.mr-12 {
    margin-right: 12px;
}
.container, .container_sm {
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
}
.container_sm {
    /* stylelint-disable-next-line at-rule-no-unknown */

    max-width: 500px;
}
.container_flex {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}
.grecaptcha-badge {
    display: none;
}

/* General Styles */
html {
  font-size: 16px;
}
@media (max-width: 1200px) {
  html {
    font-size: 14px;
  }
}
@media (max-width: 992px) {
  html {
    font-size: 13px;
  }
}
@media (max-width: 768px) {
  html {
    font-size: 11px;
  }
}
@media (max-width: 576px) {
  html {
    font-size: 10px !important;
  }
  .app-subtitle {
    margin-top: .5rem !important;
  }
  .logo-icon {
    -webkit-align-self: center !important;
            align-self: center !important;
  }
}
.placeholder-container {
  font-family: Acrom;
  background-color: #f5f5f5;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 20px;
  height: 100vh;
}
.logo-icon {
    width: 20rem;
    -webkit-align-self: flex-start;
            align-self: flex-start;
}
h2 {
  text-align: left;
  font-weight: 600;
  font-size: 3rem;
  line-height: 1;
  margin: 0;
  margin-bottom: 1rem;
  color: #5A5858;
}
p {
  text-align: left;
  font-size: 2rem;
  color: #5A5858;
}
/* Logo */
.logo {
  background-color: white;
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}
/* Content Section */
.content {
  padding: 20px;
  border-radius: 10px;
  max-width: 800px;
  width: 100%;
}
.content  p {
    font-size: 1.8rem;
}
/* App Download Section */
.app-download {
  background-color: rgba(107, 106, 105, 0.6);
  padding: 19px;
  border-radius: 14px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  grid-gap: 15px;
  gap: 15px;
  margin-top: 1.5rem;
}
.app-icon {
  /* width: 40px;
    height: 40px; */
  background-color: black;
  color: white;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  border-radius: 50%;
  font-size: 20px;
}
.app-info {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  text-align: left;
  min-width: 140px;
}
.app-title {
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
}
.app-subtitle {
  color: #fff;
  font-size: 1.7rem;
line-height: 1;
}
.app-buttons {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
}
.app-store, .google-play {
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}
.app-store {
  background-color: black;
  color: white;
}
.google-play {
  background-color: #ccc;
  color: black;
}
/* Support Section */
.support {
-webkit-align-self: flex-start;
        align-self: flex-start;
}
.support p {
  font-size: 1.5rem;
  color: gray;
}
.phone {
  font-weight: 600;
  color: black;
}

