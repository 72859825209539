.bPage {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: hidden;

    &__main {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        overflow-x: hidden;
        overflow-y: auto;
        position: relative;
    }
}
