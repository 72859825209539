.formSlider {
    padding-bottom: 24px;
    padding-top: 12px;
    position: relative;

    &__line {
        background: color($cBlack a(0.1));
        border-radius: 3px;
        height: 6px;
        position: relative;
        width: 100%;
    }

    &__pin {
        height: 100%;
        position: absolute;
        width: 0;

        &::after {
            background: #a8b1bf;
            border-radius: 50%;
            box-shadow: 0 3px 5px 0 rgba(12, 12, 66, 0.2);
            content: '';
            cursor: pointer;
            height: 16px;
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 16px;
        }
    }

    &__label {
        bottom: 0;
        display: flex;
        justify-content: center;
        position: absolute;
        text-align: center;
        width: 0;

        &_first {
            justify-content: flex-start;
        }

        &_last {
            justify-content: flex-end;
        }
    }
}
