@bootstrap-4-grid;

/* stylelint-disable-next-line selector-max-type */
.row div[class^='col'] {
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
}

.row {
    margin-left: -10px;
    margin-right: -10px;
}

.mb-auto {
    margin-bottom: auto;
}

.mb-0 {
    margin-bottom: 0;
}

.mb-4 {
    margin-bottom: 4px;
}

.mb-8 {
    margin-bottom: 8px;
}

.mb-16 {
    margin-bottom: 16px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-24 {
    margin-bottom: 24px;
}

.mb-36 {
    margin-bottom: 36px;
}

.mb-40 {
    margin-bottom: 40px;
}

.mt-auto {
    margin-top: auto;
}

.mt-0 {
    margin-top: 0;
}

.mt-4 {
    margin-top: 4px;
}

.mt-8 {
    margin-top: 8px;
}

.mt-16 {
    margin-top: 16px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-24 {
    margin-top: 24px;
}

.mt-36 {
    margin-top: 36px;
}

.mt-40 {
    margin-top: 40px;
}

.mr-12 {
    margin-right: 12px;
}
