.flex {
    display: flex;
    flex-direction: column;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    &_horizontal {
        flex-direction: row;
    }

    &__bar {
        flex: none;
        position: relative;
    }

    &__scroll {
        flex: auto;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        position: relative;
    }
}
