.bPopup {
    &__overlay {
        background-color: color($cBlack a(0.5));
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        left: 0;
        padding: 8px 0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 150;

        &._spinner {
            z-index: 155;
        }
    }
}
