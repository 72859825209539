.bLogo {
    color: inherit;
    display: inline-block;
    height: 28px;
    width: 157px;

    &:hover {
        color: inherit;
    }
}
