.btn {
    align-items: center;
    background: $cGreen;
    border: none;
    border-radius: $brControl;
    color: $cWhite;
    cursor: pointer;
    display: inline-flex;
    font: $tfBody-2;
    font-weight: 700;
    height: $hControl;
    justify-content: center;
    overflow: hidden;
    padding: 0 16px;
    text-align: center;
    text-overflow: ellipsis;
    text-transform: uppercase;
    transition-duration: 0.4s;
    transition-property: box-shadow, color, background, border-color;
    transition-timing-function: ease-in-out;
    vertical-align: middle;
    white-space: nowrap;

    &:hover,
    &:active,
    &:focus {
        color: $cWhite;
        text-decoration: none;
    }

    &:hover,
    &:focus {
        background-color: $cDarkGreen;
    }

    &:active {
        background-color: $cExtraDarkGreen;
    }

    &__icon,
    &__leftIcon {
        height: 24px;
        width: 24px;
    }

    &__leftIcon {
        margin-right: 8px;
    }

    &_onlyIcon {
        padding: 12px;
        width: $hControl;
    }

    /* stylelint-disable no-descending-specificity */
    &_borderGreen,
    &_borderRed,
    &_borderBlack {
        background-color: transparent;
        border-style: solid;
        border-width: 2px;
        color: $cBlack;

        &:hover,
        &:focus,
        &:active {
            background-color: inherit;
            color: inherit;
        }
    }

    &_borderGreen {
        border-color: $cGreen;
    }

    &_borderRed {
        border-color: $cRed;
    }

    &_borderBlack {
        border-color: #000;
    }
    /* stylelint-enable no-descending-specificity */

    &_hasShadow {
        box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
    }

    &_bgTransparent {
        background-color: transparent;
        color: inherit;

        &:hover,
        &:active,
        &:focus {
            background-color: inherit;
            color: inherit;
        }
    }

    &_bgOnGreen {
        background-color: $cWhite;
        color: $cGreen;

        &:hover {
            background-color: $cWhite;
            color: $cGreen;
        }
    }

    &_bgOnRed {
        background-color: $cWhite;
        color: $cRed;

        &:hover {
            background-color: $cWhite;
            color: $cRed;
        }
    }

    &_bgBlack {
        background-color: #000;

        &:hover, &:active, &:focus {
            background-color: #333;
        }
    }

    &_widthFull {
        display: flex;
        padding: 0 10px;
        width: 100%;
    }

    &._disabled,
    &[disabled] {
        cursor: default;
        opacity: 0.5;
        pointer-events: none;
    }
}
