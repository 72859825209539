.input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: $cWhite;
    border: 1px solid $cGray-65;
    border-radius: $brControl;
    box-shadow: none;
    color: $cBlack;
    display: inline-block;
    font: $tfBody-1;
    height: $hControl;
    outline: none;
    padding: 0 16px;
    text-align: inherit;
    text-overflow: ellipsis;
    width: 100%;

    &:focus {
        border-color: $cGreen;
    }

    /* stylelint-disable-next-line order/order */
    &:focus::placeholder {
        color: transparent;
    }

    /* stylelint-disable-next-line no-descending-specificity */
    &::placeholder {
        color: $cGray-60;
    }

    &._disabled,
    &[disabled] {
        opacity: 0.5;
    }

    &.hasError {
        border-color: $cRed;
    }

    &.lg {
        font-size: 24px;
        line-height: 28px;
    }
}
