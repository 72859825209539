.bAutomatCodeHint {
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;

    &__caption {
        display: inline-block;
        margin-bottom: 28px;
        position: relative;
    }

    &__callout {
        height: 93px;
        position: absolute;
        right: -12px;
        top: calc(100% + 5px);
        width: 170px;
        z-index: 1;
    }

    &__imgWrapper {
        height: 205px;
        max-width: 320px;
        position: relative;
        width: 100%;
    }

    &__img {
        height: 305px;
        left: 50%;
        max-height: none;
        max-width: none;
        position: absolute;
        top: 0;
        transform: translateX(-50%);
    }
}
