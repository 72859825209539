.textarea {
    /* stylelint-disable-next-line at-rule-no-unknown */
    @extend .input;

    height: auto;
    max-width: 100%;
    min-height: 80px;
    overflow: auto;
    padding: 8px 12px;
    width: 100%;
}
