.container {
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
}

.container_sm {
    /* stylelint-disable-next-line at-rule-no-unknown */
    @extend .container;

    max-width: 500px;
}

.container_flex {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.grecaptcha-badge {
    display: none;
}