.radio {
    /* stylelint-disable-next-line at-rule-no-unknown */
    @extend .checkbox;

    &__label {
        /* stylelint-disable-next-line at-rule-no-unknown */
        @extend .checkbox__label;

        &::before {
            border-radius: 50%;
        }
    }

    &__labelText {
        /* stylelint-disable-next-line at-rule-no-unknown */
    }

    &__input {
        /* stylelint-disable-next-line at-rule-no-unknown */
        @extend .checkbox__input;

        &:checked + .radio__label {
            &::after {
                background: $cGreen;
                border-radius: 50%;
                content: '';
                height: 12px;
                left: 4px;
                position: absolute;
                top: 4px;
                width: 12px;
            }
        }

        &[disabled] + .radio__label,
        &._disabled + .radio__label {
            cursor: default;
            opacity: 0.5;
        }
    }
}
