/* stylelint-disable unit-blacklist */
.icon {
    display: inline-block;
    fill: currentColor;
    flex: none;
    height: 1em;
    line-height: 1em;
    position: relative;
    vertical-align: middle;
    width: 1em;

    &_lg {
        font-size: 24px;
    }

    &_sizeFull {
        height: 100%;
        width: 100%;
    }
}
/* stylelint-enable */
