.bPaysystemIcons {
    display: flex;
    justify-content: space-between;

    &__item {
        height: 60px;
        margin: 9px;
        width: 94px;
    }
}
