/* stylelint-disable */
.spinner {
    display: flex;
    justify-content: center;

    > div {
        animation: bouncing-loader 0.6s infinite alternate;
        background: $cGreen;
        border-radius: 50%;
        height: 1rem;
        margin: 3rem 0.2rem;
        width: 1rem;

        &:nth-child(2) {
            animation-delay: 0.2s;
        }

        &:nth-child(3) {
            animation-delay: 0.4s;
        }
    }

    &._small > div {
        margin-bottom: 0;
    }

    &._white > div {
        background: $cWhite
    }
}

@keyframes bouncing-loader {
    from {
        transform: translateY(0);
    }

    to {
        transform: translateY(-1rem);
    }
}

/* stylelint-enable */
