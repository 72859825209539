.bMap {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    /* stylelint-disable-next-line order/order */
    &__placeholderImg {
        height: 100%;
        left: 0;
        object-fit: cover;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &__content {
        bottom: 0;
        display: flex;
        left: 0;
        max-height: 100%;
        pointer-events: none;
        position: absolute;
        width: 100%;
    }

    &__contentContainer {
        flex-grow: 1;
        pointer-events: auto;
    }

    &__btn {
        margin: 20px 0;
    }

    &__panel {
        margin-top: 8px;

        &:last-child {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        &._minimized {
            /* stylelint-disable selector-max-type */
            .headline,
            hr,
            .btn {
                display: none;
            }

            tr:not([class*='cardSession__main']) {
                display: none;
            }

            tr.hr {
                border-top: none;
            }
            /* stylelint-enable selector-max-type */
        }
    }

    &__table {
        border-collapse: collapse;
        table-layout: fixed;
        width: 100%;

        td,
        th {
            padding: 0;
            vertical-align: top;
            white-space: nowrap;

            /* stylelint-disable-next-line selector-max-type */
            &:first-child {
                text-align: left;
            }

            /* stylelint-disable-next-line selector-max-type */
            &:last-child {
                text-align: right;
            }
        }
    }

    /* stylelint-disable selector-max-compound-selectors, selector-max-type */
    [class*='ymaps-'] a {
        font-size: inherit;
    }

    &-cluster {
        border: 2px solid $cGreen;
        border-radius: 50%;
        box-sizing: border-box;
        font-size: 16px;
        height: 40px;
        left: -20px;
        overflow: hidden;
        position: relative;
        top: -20px;
        width: 40px;

        &::before {
            background: $cWhite;
            bottom: 0;
            content: '';
            left: 0;
            opacity: 0.7;
            position: absolute;
            right: 0;
            top: 0;
        }

        &-content {
            align-items: center;
            background: $cGreen;
            border-radius: 50%;
            color: $cWhite;
            display: inline-flex;
            height: 28px;
            justify-content: center;
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 28px;
        }
    }

    &__geolocationBtn {
        position: absolute;
        z-index: 1;
        top: 32px;
        right: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        padding: 0;
        background-color: #fff;
        border: none;
        border-radius: 50%;
        box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
        cursor: pointer;
    }

    &__geolocationBtn:active {
        opacity: 0.9;
    }
}
