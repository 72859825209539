.aligner {
    align-items: center;
    display: flex;

    &__left {
        flex: 0 0 auto;
    }

    &__right {
        flex: 0 1 auto;
        margin-left: auto;
        min-width: 0;
        padding-left: 10px;
    }

    &_top {
        align-items: flex-start;
    }

    &_baseline {
        align-items: baseline;
    }
}
