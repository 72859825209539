.badge {
    align-items: center;
    background: $cGrey;
    border: 1px solid transparent;
    border-radius: 2px;
    box-sizing: border-box;
    color: $cWhite;
    display: inline-flex;
    font-size: 10px;
    height: 24px;
    max-width: 100%;
    padding: 0 16px;
    text-transform: uppercase;

    &_red {
        background: $cRed;
    }

    &_green {
        background: $cGreen;
    }

    &__text {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
