.select {
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 6"%3E%3Cpath fill="%2339B369" class="st0" d="M0.3,0.3c0.4-0.4,1-0.4,1.4,0L5,3.6l3.3-3.3c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1,0,1.4l-4,4 c-0.4,0.4-1,0.4-1.4,0l-4-4C-0.1,1.3-0.1,0.7,0.3,0.3z"/%3E%3C/svg%3E%0A');
    background-position: calc(100% - 20px) center;
    background-repeat: no-repeat;
    background-size: 10px 6px;
    padding-right: 48px;

    /* stylelint-disable-next-line order/order */
    &:invalid {
        color: $cGray-60;
    }

    /* stylelint-disable-next-line no-descending-specificity */
    &::-ms-expand {
        display: none; /* remove default arrow on ie10 and ie11 */
    }

    /* stylelint-disable-next-line at-rule-no-unknown */
    @extend .input;
}
