.bNavLinks {
    list-style: none;
    margin: 0;
    padding: 0;

    &__link {
        color: inherit;
        cursor: pointer;
        display: block;
        font: $tfBody-2;
        font-weight: 700;
        padding-bottom: 12px;
        padding-top: 12px;

        &:hover {
            color: inherit;
        }
    }

    &__item {
        &_divided {
            border-bottom: 1px solid color($cWhite a(0.1));
            padding-bottom: 8px;
        }

        &_divided + & {
            padding-top: 8px;
        }
    }
}
