.bStylizedList {
    list-style: none;
    margin: 0;
    padding: 0;

    &__item {
        display: flex;

        & + & {
            margin-top: 16px;
        }
    }

    &__text {
        align-items: center;
        display: flex;
        font: $tfBody-2;
    }

    &_divided &__item:not(:last-child) {
        border-bottom: 1px solid color($cBlack a(0.3));
        padding-bottom: 20px;
    }

    &_divided.textWhite &__item {
        border-color: color($cWhite a(0.3));
    }

    /* stylelint-disable selector-max-compound-selectors */
    &_divided &__item + &__item {
        margin-top: 20px;
    }

    &_styleOrder {
        counter-reset: orderList;
    }

    &_styleOrder &__bullet {
        &::before {
            content: counter(orderList);
            counter-increment: orderList;
        }
    }
}
