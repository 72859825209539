.bHeader {
    background-color: $cWhite;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12);
    display: flex;
    min-height: 64px;
    position: relative;
    z-index: $zHeader;

    &__container {
        display: flex;
    }

    &__nav {
        align-items: center;
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
    }

    &__goBack {
        color: $cGreen;
        padding: 0;
        text-transform: none;

        &:hover {
            color: $cGreen;
        }
    }

    &__menuBtn {
        color: $cGreen;
        margin-right: -12px;

        &:hover {
            color: $cGreen;
        }
    }
}
