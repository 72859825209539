.bOrderedList {
    counter-reset: orderList;
    list-style: none;
    margin: 0;
    padding: 0;

    &__number {
        align-items: center;
        border: 2px solid $cBlack;
        border-radius: 50%;
        display: flex;
        font: $tfTitle;
        font-weight: 700;
        height: 48px;
        justify-content: center;
        margin-right: 16px;
        min-width: 48px;
        width: 48px;

        &::before {
            content: counter(orderList);
            counter-increment: orderList;
        }
    }

    &__item {
        align-items: center;
        display: flex;

        & + & {
            margin-top: 16px;
        }
    }
}
