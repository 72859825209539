.bIconizedList {
    counter-reset: orderList;
    list-style: none;
    margin: 0;
    padding: 0;

    &__bullet {
        align-items: center;
        border-radius: 50%;
        display: flex;
        font: $tfTitle;
        font-weight: 700;
        height: 48px;
        justify-content: center;
        margin-right: 16px;
        min-width: 48px;
        width: 48px;
    }

    &__item {
        display: flex;

        & + & {
            margin-top: 16px;
        }
    }

    &_bulletBgGreen &__bullet {
        background-color: $cLightGreen;
    }

    &_divided &__item:not(:last-child) {
        border-bottom: 1px solid color($cBlack a(0.3));
        padding-bottom: 20px;
    }

    &_divided.textWhite &__item {
        border-color: color($cWhite a(0.3));
    }

    /* stylelint-disable selector-max-compound-selectors */
    &_divided &__item + &__item {
        margin-top: 20px;
    }
}
