/* stylelint-disable */
.checkbox {
    display: inline-flex;
    max-width: 210px;

    &__btn {
        border-radius: 3px;
        box-shadow: inset 0 0 0 2px currentColor;
        display: block;
        height: 16px;
        margin-right: 12px;
        min-width: 16px;
        position: relative;
        width: 16px;

        &::before {
            border-radius: 50%;
            content: '';
            height: calc(100% + 24px);
            left: 50%;
            opacity: 0;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%) scale(0.5);
            transition: all 0.3s ease-out;
            width: calc(100% + 24px);
            z-index: -1;
        }

        &::after {
            background-image: url('data:image/svg+xml,%3Csvg viewBox="0 0 10 7" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M1.70711 2.29289C1.31658 1.90237 0.683417 1.90237 0.292893 2.29289C-0.0976311 2.68342 -0.0976311 3.31658 0.292893 3.70711L1.70711 2.29289ZM4 6L3.29289 6.70711C3.68342 7.09763 4.31658 7.09763 4.70711 6.70711L4 6ZM9.70711 1.70711C10.0976 1.31658 10.0976 0.683417 9.70711 0.292893C9.31658 -0.0976311 8.68342 -0.0976311 8.29289 0.292893L9.70711 1.70711ZM0.292893 3.70711L3.29289 6.70711L4.70711 5.29289L1.70711 2.29289L0.292893 3.70711ZM4.70711 6.70711L9.70711 1.70711L8.29289 0.292893L3.29289 5.29289L4.70711 6.70711Z" fill="white"/%3E%3C/svg%3E%0A');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            content: '';
            height: 7px;
            left: 50%;
            opacity: 0;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 10px;
        }
    }

    &__label {
        color: $cGray-20;
        text-align: left;

        a {
            font-size: inherit;
            line-height: inherit;
        }
    }

    &__input:checked + &__btn {
        background-color: $cGreen;
        box-shadow: none;

        &::after {
            opacity: 1;
        }
    }

    &__input:disabled ~ &__btn,
    &__input:disabled ~ &__label {
        opacity: 0.5;
    }

    @media (hover: hover) {
        &:hover &__input:not(:disabled) + &__btn,
        &__input:focus + &__btn {
            &::before {
                opacity: 0.5;
                transform: translate(-50%, -50%) scale(1);
            }
        }

        &:hover &__input:not(:disabled) + &__btn {
            &::before {
                background-color: $cGray-75;
            }
        }

        &__input:focus + &__btn {
            &::before {
                background-color: $cGray-85;
            }
        }
    }
}
