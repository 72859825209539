.btnGroup {
    border: 1px solid $cGreen;
    border-radius: 2px;
    box-sizing: border-box;
    display: inline-flex;
    height: 40px;
    overflow: hidden;

    &__item {
        align-items: center;
        background: transparent;
        cursor: pointer;
        display: flex;
        justify-content: center;
        padding: 0 10px;

        &:hover {
            background: $cGreen;
            color: $cWhite;
        }

        & + & {
            border-left: 1px solid $cGreen;
        }

        &._active {
            background: $cGreen;
            color: $cWhite;
            cursor: default;
        }
    }

    &_block {
        display: flex;

        .btnGroup__item {
            flex: 1 0 0;
        }
    }
}
