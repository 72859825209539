/* General Styles */
html {
  font-size: 16px;
}

@media (max-width: 1200px) {
  html {
    font-size: 14px;
  }
}

@media (max-width: 992px) {
  html {
    font-size: 13px;
  }
}

@media (max-width: 768px) {
  html {
    font-size: 11px;
  }
}

@media (max-width: 576px) {
  html {
    font-size: 10px !important;
  }
  .app-subtitle {
    margin-top: .5rem !important;
  }
  .logo-icon {
    align-self: center !important;
  }
}
.placeholder-container {
  font-family: Acrom;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  height: 100vh;
}

.logo-icon {
    width: 20rem;
    align-self: flex-start;
}

h2 {
  text-align: left;
  font-weight: 600;
  font-size: 3rem;
  line-height: 1;
  margin: 0;
  margin-bottom: 1rem;
  color: #5A5858;
}
p {
  text-align: left;
  font-size: 2rem;
  color: #5A5858;
}
/* Logo */
.logo {
  background-color: white;
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

/* Content Section */
.content {
  padding: 20px;
  border-radius: 10px;
  max-width: 800px;
  width: 100%;
}
.content  p {
    font-size: 1.8rem;
}
/* App Download Section */
.app-download {
  background-color: rgba(107, 106, 105, 0.6);
  padding: 19px;
  border-radius: 14px;
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 1.5rem;
}

.app-icon {
  /* width: 40px;
    height: 40px; */
  background-color: black;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 20px;
}

.app-info {
  flex-grow: 1;
  text-align: left;
  min-width: 140px;
}

.app-title {
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
}

.app-subtitle {
  color: #fff;
  font-size: 1.7rem;
line-height: 1;
}

.app-buttons {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.app-store,
.google-play {
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}

.app-store {
  background-color: black;
  color: white;
}

.google-play {
  background-color: #ccc;
  color: black;
}

/* Support Section */
.support {
align-self: flex-start;
}

.support p {
  font-size: 1.5rem;
  color: gray;
}

.phone {
  font-weight: 600;
  color: black;
}
