.bBullet {
    align-items: center;
    border-radius: 50%;
    display: flex;
    font: $tfTitle;
    font-weight: 700;
    height: 48px;
    justify-content: center;
    margin-right: 16px;
    min-width: 48px;
    width: 48px;

    &_bgGreen {
        background-color: $cLightGreen;
    }

    &_borderBlack {
        border: 2px solid $cBlack;
    }
}
