.tooltip {
    background: $cWhite;
    border-radius: 3px;
    color: $cBlack;
    filter: drop-shadow(0 2px 5px rgba(0, 0, 0, 0.2));
    font-size: 12px;
    opacity: 0;
    padding: 6px 12px;
    position: absolute;
    transition: all 0.3s ease;
    transition-property: opacity;
    visibility: hidden;

    &::after {
        background: #ffffff;
        content: '';
        height: 12px;
        left: 50%;
        position: absolute;
        top: 100%;
        transform: translate(-50%, -50%) rotate(45deg);
        width: 12px;
    }

    &._show {
        opacity: 1;
        visibility: visible;
    }
}
