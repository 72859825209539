.bPaymentCard {
    background-color: $cGray-85;
    border-radius: 16px;
    padding: 20px 20px 28px;

    &__paymentSystemLogo {
        height: 60px;
        margin-bottom: 28px;
        width: 72px;
    }

    &__number {
        font: $tfHeadline;
        margin-bottom: 12px;
    }

    &__hiddenSymbols {
        letter-spacing: 5px;
    }

    &__name {
        font: $tfBody-2;
    }
}
