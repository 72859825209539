.popover {
    background: $cWhite;
    border-radius: 8px;
    color: $cBlack;
    filter: drop-shadow(0 8px 15px rgba($cBlack, 0.5));
    max-width: 300px;
    position: absolute;

    &::before {
        background: $cWhite;
        content: '';
        height: 18px;
        left: -7px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
        width: 18px;
    }

    &__close {
        align-items: center;
        cursor: pointer;
        display: flex;
        font-size: 8px;
        height: 24px;
        justify-content: center;
        opacity: 0.5;
        position: absolute;
        right: 0;
        top: 0;
        width: 24px;
        z-index: 1;
    }

    &__content {
        padding: 20px 25px;
    }
}
