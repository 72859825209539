@font-face {
    font-family: Acrom;
    font-weight: 400;
    src: url('../assets/fonts/Acrom/acrom-regular.woff2') format('woff2'),
        url('../assets/fonts/Acrom/acrom-regular.woff') format('woff');
}

@font-face {
    font-family: Acrom;
    font-weight: 500;
    src: url('../assets/fonts/Acrom/acrom-medium.woff2') format('woff2'),
        url('../assets/fonts/Acrom/acrom-medium.woff') format('woff');
}

@font-face {
    font-family: Acrom;
    font-weight: 700;
    src: url('../assets/fonts/Acrom/acrom-bold.woff2') format('woff2'),
        url('../assets/fonts/Acrom/acrom-bold.woff') format('woff');
}

@font-face {
    font-family: Acrom;
    font-weight: 900;
    src: url('../assets/fonts/Acrom/acrom-extrabold.woff2') format('woff2'),
        url('../assets/fonts/Acrom/acrom-extrabold.woff') format('woff');
}
