.bSmsCodeInput {
    display: inline-flex;

    &__item {
        border-radius: 4px;
        padding: 0 10px;
        width: 48px;

        & + & {
            margin-left: 8px;
        }
    }

    &.hasError &__item {
        border-color: $cRed;
    }
}
