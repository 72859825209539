.panel {
    background-color: $cWhite;
    border-radius: 4px;
    margin-left: -12px;
    margin-right: -12px;
    padding: 20px;

    &_hasShadow {
        box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
    }
}
