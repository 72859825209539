.section {
    margin: 0 auto;
    max-width: 1140px;
    padding-left: 30px;
    padding-right: 30px;

    &_short {
        max-width: 1000px;
    }
}
